import React, { useState } from "react"
import ReviewCard from "./card"
import ReviewsQuery from "./query"
import styled from "styled-components/macro"

export default function ReviewsComponent({ data }) {
  const [limit, setLimit] = useState(80)

  return (
    <ReviewsQuery limit={limit}>
      {({ reviews, hasNextPage }) => (
        <Reviews>
          <pw-row>
            {reviews.map(({ data }, index) => {
              return (
                <pw-col nm="4" xxl="2" xl="3" lg="4" md="6" sm="12" key={index}>
                  <ReviewCard
                    title={data.title || data.reviewer}
                    comment={data.comment}
                    id={data.slug}
                    rating={data.rating}
                    source={data.source}
                    link={data.link}
                    avatarUrl={data.avatar !== null && data.avatar[0].url}
                    sourceLogo={
                      data.sourceLogo !== null && data.sourceLogo[0].url
                    }
                    headerImage={
                      data.backgroundUrl !== null && data.backgroundUrl[0].url
                    }
                  />
                </pw-col>
              )
            })}
          </pw-row>
          {hasNextPage && (
            <LoadMore>
              <p-button onClick={() => setLimit(limit + 10)}>
                Load more
              </p-button>
            </LoadMore>
          )}
        </Reviews>
      )}
    </ReviewsQuery>
  )
}

const Reviews = styled.section`
  position: relative;
  background: var(--color-leaf-light);
  overflow: hidden;
  padding-right: 16px;
  padding-left: 16px;
  margin-right: auto;
  margin-left: auto;
  padding: 10rem 0 14rem;
`

const LoadMore = styled.div`
  max-width: 150px;
  margin: 30px auto 0;
`
