import React from "react"
import SEO from "../components/seo"

import Layout from "../components/layout"
import Hero from "../components/hero"
import { Main } from "../components/styles"
import Reviews from "../components/reviews/"

const IndexPage = () => (
  <Layout>
    <SEO />
    <Hero />
    <Main>
      <Reviews />
    </Main>
  </Layout>
)

export default IndexPage
